.home {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;
  &__block2 {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 80px;

    h2{
      font-family: sans-serif;
    }
        p{
          font-size: 16px;
          font-family: sans-serif;
          text-align: justify
        };
  }
  &__block1 {
    width: 80%;
    display: flex;
  padding-top: 100px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 80px;
h2{
  font-family: sans-serif;
}
    p{
      font-size: 16px;
      font-family: sans-serif;
      text-align: justify
    };
  }
  
}
