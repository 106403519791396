.gallery{
    &__detail{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 100px;
    }
}

.image-gallery-slide {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-gallery-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translateY(-10%);
  }
  
  .image-gallery-icon {
    display: none;
  }
  
  .image-gallery-fullscreen-button {
    display: none;
  }
  
  @media (max-width: 767px) {
    .image-gallery-image img {
      width: 100vw;
      height: 100vh;
      object-fit: cover;
      transform: none;
    }
  }
  
  /* Mixin para tablets */
  @media (min-width: 768px) and (max-width: 1023px) {
    .image-gallery-image img {
      width: 100vw;
      height: 100vh;
      object-fit: cover;
      transform: none;
    }
  }