.teams{
    &__title{
        font-weight: 500;
        font-size: 24px;
        letter-spacing: 1px;
        background: rgba(4,123,52,1);
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        color: white;
        text-shadow: 0px 0px 3px black;
    }
    &__link{
        text-decoration: none;
        color: black;
    }
    &__block{
            padding-top: 58px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            margin-bottom: 60px ;
        
    }
    &__mosaic{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    &__card{
        display: flex;
        border-radius: 10px;
        width: 320px;
        height: 200px;
        margin: 10px;
        padding: 30px;
        flex-direction: column;
        align-items: center;
        background-image: linear-gradient(75deg, #fdfbfb 0%, #ebedee 100%);
        filter: drop-shadow(0px 1px 2px rgb(85, 85, 85));
        
        &__img{
            height: 80px;
        }
        
        &__fieldset{
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Montserrat';
            flex-direction:column ;
        }
        &__legend{
            margin: 0;
        }
        &__name{
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 500;
        }
        &__info{
       
            margin: 10px;
        }

        @media (max-width: 475px) {
            padding: 10px;
        }
    }
}