.pdf{
    &-txt{
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 1px;
    }
    &-container{
        display: flex;
        align-items: center;
        justify-content: center;

    }
    &-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
    }
    &-icon{
        font-size: 24px;
        margin:10px;
    }
}