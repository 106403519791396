.competitions{
    &__container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 90%;
    }
    &__block{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding-top: 58px;
        padding-bottom: 60px;
        &__title{
            font-size: 30px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    &__title{
        text-transform: uppercase;
        font-weight: 400;
        min-width: 300px;
    }
    &__pdf{
        padding-top: 30px;
        width: 500px;
        height: 160px;
        background-image: linear-gradient(75deg, #fdfbfb 0%, #ebedee 100%);
        filter: drop-shadow(0px 1px 2px rgb(85, 85, 85));
        padding-bottom: 30px;
        margin: 10px;
        min-width: 300px;
    }
    &__icon{
        font-size: 40px;
    }

}