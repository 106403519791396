.comingsoon{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;

    &__img{
        width: 180px;
        margin-bottom: 20px;
    }

    &__legend{
        padding: 0;
        margin: 0;
        font-size: 18px;
        text-align: center;
    }
}