.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f8f8f8;
    padding: 10px;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    z-index: 100;
  }
  
  .cookie-banner button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .cookie-banner a {
    color: #007bff;
    text-decoration: none;
  }