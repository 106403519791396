.team-detail{
    padding-top: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__text{
        font-weight: 400;
        font-size: 16px;
    }

    &__gallery{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    &__img{
        width: 600px;
        margin-top: 30px;

        @media (max-width: 475px) {
            width: 350px;
        }
    }

    &__info{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-family: 'Montserrat';
        padding-bottom: 40px;
    }
    &__label{
        text-transform: uppercase;
        font-weight: 500;
        font-size: 20px;
    }

    &__title{
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1px;
        background: rgba(4,123,52,1);
        width: 100%;
        color: white;
        padding-top:20px;
        padding-bottom: 20px;
        text-shadow: 0px 0px 3px black;

    }
    &__history{
        width: 80%;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.5em;
        text-align: justify;
    }
}