.regulation{
    &__block{
        display: flex;
        padding-top:60px ;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    &__section{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    &__title{
        text-transform: uppercase;
        font-size: 30px;
        font-weight: 500;
    }
    &__container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 90%;
        padding-bottom: 40px;
        &__title{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            h2{
                text-transform: uppercase;
                font-weight: 500;
            }
            img{
                height: 60px;
            }
        }
    }
    &__card{
        padding-top: 30px;
        width: 350px;
        height: 140px;
        background-image: linear-gradient(75deg, #fdfbfb 0%, #ebedee 100%);
        filter: drop-shadow(0px 1px 2px rgb(85, 85, 85));
        padding-bottom: 30px;
        margin: 10px;
        min-width: 300px;
        &__title{
            text-transform: uppercase;
            margin: 10px;
            text-align: center;
        }
    }
}