.file {
  &-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    flex-direction: column;
  }
  &__form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__input{
    width: 350px;
  }
  &__btn{
    padding: 20px;
    font-size: 16px;
    text-transform: uppercase;
    border: none;
    font-weight: 600;
    border-radius: 10px;
    &:hover{
        background: gray;
        cursor: pointer;
    }
  }
}
