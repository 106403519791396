@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400&display=swap');

.App {
  text-align: center;
  font-family: 'Barlow Condensed', sans-serif;
}
.pdf-btn{
  background: rgba(6,60,27,1);
  border: none;
  padding: 10px;
  border-radius: 10px;
  color: white;
  font-weight: 300;
  text-transform: uppercase;
  transition: opacity 0.4s ease-in;
  cursor: pointer;
  &:hover{
    color:rgba(6,60,27,1) ;
    background: white;
    font-weight: 600;
    filter: drop-shadow(0px 0px 4px black);
  }
}

.title{
  &__h1{
    text-transform: uppercase;
        background: rgba(6,60,27,1);
        width: 100%;
        color: white;
        font-weight: 500;
        letter-spacing: 1px;
        padding-top: 10px;
        padding-bottom: 10px;
  }
}
