.news{
    &__block{
        width: 100%;
        display: flex;
        padding-top: 58px;
        padding-bottom: 100px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    &__date{
        text-align: right;
    }
    &__title{
        font-size: 40px;
        text-transform: uppercase;
        margin: 0;
    }
    &__feed{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    &__stories{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 30px;
        background-image: linear-gradient(75deg, #fdfbfb 0%, #ebedee 100%);
        filter: drop-shadow(0px 1px 2px rgb(85, 85, 85));
        padding-bottom: 30px;
        margin: 10px;
        min-width: 300px;
        width:80%;

        &__title{
            font-size: 30px;
            font-weight: 500;
            text-transform: uppercase;
        }
        &__img{
            width: 60%;
        
            @media (max-width: 475px) {
                width: 80%;
            }
        }
    }
}