.header {
    position: fixed;
    font-family: 'Barlow Condensed', sans-serif;
    width: 100%;
    z-index: 100;

    &__user{
      color: white;
      font-size: 26px;
      margin-left: 10px;

    }
    &-menu { 
      z-index: 150;
     
    }
    &-block {
      background: linear-gradient(315deg, rgba(6,60,27,1) 0%, rgba(4,123,52,1) 50%, rgba(6,60,27,1) 100%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
      font-weight: 700;
      position: relative;
    }
    &-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 250px;
      h2{
        font-size: 16px;
        text-transform: uppercase;
        text-align: right;
        font-weight: 400;
        color:  white;
        padding-right: 10px;
      }
      img{
        height: 60px;
        padding: 10px 0px 10px 0px;
      }
     
    }
    &-icons {
      display: flex;
      width: 100px;
      align-items: center;
      justify-content: center;
      
    
    }
    &-icons__market {
      height: 30px;
      cursor: pointer;
    }
    &-icons__language {
      color: white;
      margin-right: 20px;
      font-size: 26px;
    }
    &-nav {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      width: 50%;
      height: 100vh;
      position: absolute;
      transition: all 0.4s ease-in-out;
      top: 0;
      z-index: 90;
      background:rgba(6, 60, 27, 0.85);
      background-size: cover;
      backdrop-filter: blur(5px);
      text-transform: uppercase;
      padding-top: 40px;

   

      &__links {
        text-decoration: none;
        color: white;
        padding: 14px 20px 14px 20px;
        letter-spacing: 3px;
        margin-right: 20px;
        font-weight: 600;
        transition: all 0.4s ease-in-out;
    
        &:hover {
          font-weight: 800;
          background: rgba(255, 255, 255, 0.815);
          color:rgba(6,60,27,1);
        }
      }
  
      &__icons {
        transition: all 0.3s ease;
        margin-right: 10px;
        &:hover{
          filter: drop-shadow(0px 0px 8px white);
        }
      }
      &__documents{
        background: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &-nav__sections {
      display: flex;
      flex-direction: column;
      padding: 30px 0px 30px 0px;
      width: 100%;
    }
   
   
    @media (max-width: 1080px) {

      &-nav {
        width: 100%;
        height: 100vh;
  
        &__icons {
          padding: 0;
        }
      }
    }
  }