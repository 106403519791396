.gallery{
    &__block{
       display: flex;
       align-items: center;
       justify-content: center;
       flex-direction: column;
       padding-top: 60px;
       padding-bottom: 100px;

    }
    &__card{
        padding: 10px;
        background-image: linear-gradient(75deg, #fdfbfb 0%, #ebedee 100%);
        filter: drop-shadow(0px 1px 2px rgb(85, 85, 85));
        padding-bottom: 30px;
        margin: 10px;
        min-width: 300px;
    }
    &__title{
        text-transform: uppercase;
        background: rgba(6,60,27,1);
        width: 100%;
        color: white;
        font-weight: 500;
        letter-spacing: 1px;
    }
    &__link{
        text-decoration: none;
        color: black;
    }
    &__thumb{
        height: 400px;
    }
    &__legend{
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 500;
    }
}