.login-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding-top: 60px;
    font-family: 'Montserrat';
    position: relative;
  }
  .login-title{
    position: absolute;
    top: 58px;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 500;
    background-color:  rgba(6,60,27,1);
    letter-spacing: 1px;
    width: 100%;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 380px;
  }
  .btn-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

  }
  
  .login-form h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .login-form label {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    width: 100%;
  }
  
  .login-form input[type="email"],
  .login-form input[type="password"] {
    font-size: 14px;
    font-weight: 400;
    padding: 12px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
    margin-top: 10px;
    width: 90%;
  }
  .google-btn{
    margin-top: 10px;
  }
  .login-form button,
  .register-btn {
    background-color: rgba(6,60,27,1);
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 12px;
    border-radius: 5px;
    width: 150px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-family: 'Montserrat';
    text-transform: uppercase;
    margin-left: 10px;
  
    &:hover {
      background-color: #ffffff;
      color:  rgba(6,60,27,1);
      filter: drop-shadow(0px 1px 4px black);
    }
  }
  .register-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .login-form__forgot-password {
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 20px;
    text-align: center;
    color: #1e90ff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  
    &:hover {
      color: #0066cc;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .login-form {
      width: 90%;
      padding: 10px;
    }
  }